import firebase from 'gatsby-plugin-firebase'
import { useEffect } from 'react'

export function signIn(
  email: string,
  password: string,
  onSuccess: () => void,
): void {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch((error) => {
      if (error) {
        alert('Authentication failed')
      }
    })
    .then((res) => {
      if (res) {
        onSuccess()
      }
    })
}

export function signOut(onSuccess: () => void): void {
  firebase
    .auth()
    .signOut()
    .then(onSuccess)
    .catch(function (error) {
      alert(error.message)
    })
}

export function useAuthValidation(
  onSuccess: () => void,
  onFailed: () => void = () => null,
): void {
  return useEffect(() => {
    firebase.auth().onAuthStateChanged((userAuth) => {
      if (userAuth) {
        onSuccess()
      } else {
        onFailed()
      }
    })
  }, [onSuccess, onFailed])
}

import React from 'react'
import { Formik, Field, Form } from 'formik'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { signIn, useAuthValidation } from '../../utils/auth'
import Layout from '../../components/layouts/Layout'

function redirectToExportDataPage() {
  window.location.pathname = '/admin/export'
}

function LoginButton({ disabled }: { disabled?: boolean }): JSX.Element {
  return (
    <button
      type="submit"
      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out disabled:bg-indigo-300"
      disabled={disabled}
    >
      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
        <svg
          className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      Sign in
    </button>
  )
}

function AdminIndex(): JSX.Element {
  useAuthValidation(redirectToExportDataPage)

  const iggyIcon = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "favicon.png" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `,
  )

  return (
    <Layout className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="text-center">
          <Img
            fixed={iggyIcon.file.childImageSharp.fixed}
            className="-mt-8 mb-8"
          />
          <h2 className="mb-6 text-3xl leading-9 font-extrabold text-gray-900">
            Iggy&apos;s Admin Panel
          </h2>
        </div>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={async ({ email, password }) => {
            signIn(email, password, redirectToExportDataPage)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="rounded-md shadow-sm">
                <div>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                    placeholder="Email address"
                    aria-label="Email address"
                  />
                </div>
                <div className="-mt-px">
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                    placeholder="Password"
                    aria-label="Password"
                  />
                </div>
              </div>
              <div className="mt-6">
                <LoginButton disabled={isSubmitting} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  )
}

export default AdminIndex
